import React from 'react';

import { Outer, Inner, P, Box, DocPage } from '../components/styled';
import DocPageLayout from '../components/layout/DocPageLayout';
import privacyBackground from '../images/bg_privacy.svg';

/* eslint-disable no-irregular-whitespace */
// prettier-ignore
const PrivacyPage = () => (
  <DocPageLayout title="Privacy Policy" subtitle="Posting Date: November 4, 2018" backgroundSrc={privacyBackground}>
    <Outer>
      <Inner pt={80}>
        <DocPage>
          <Box mb={40}>
            <P>
              Sprintlio Inc. and its affiliates and agents (“<strong>Sprintlio</strong>”, “<strong>we</strong>”, “<strong>us</strong>”, “<strong>our</strong>”) provides access to the https://sprintlio.com/ website (the “<strong>Site</strong>”) in support of our Purpose (as defined in our Terms of Use). We are committed to protecting the privacy of the Personal Information of users of the Site and Account Holders (collectively, “<strong>you</strong>”, “<strong>your</strong>”). Sprintlio Inc. is the controller of your Personal Information (as defined below) and responsible for its processing, unless otherwise specified in this Privacy Policy.
            </P>
          </Box>
          <h2>1. Definitions</h2>
          <p>
            A. “<strong>Account Holder</strong>” means a user who also registers an account (“<strong>Account</strong>”) under the Site.
          </p>
          <p>
            B. “<strong>Content</strong>” means personal data or information, text, images, photos, audio, video, and all other forms of data or communication. “<strong>Your Content</strong>” means Content that you submit or transmit to or through the Site, such as invitations, and information that you display as part of your account profile. “<strong>User Content</strong>” means Content that users submit or transmit to or through the Site. “<strong>Sprintlio Content</strong>” means Content that we create and make available on the Site. “<strong>Third Party Content</strong>” means Content that is made available on the Site by parties other than you or Sprintlio. “<strong>Site Content</strong>” means all of the Content that is made available on the Site, including Your Content, User Content, Third Party Content, and Sprintlio Content.
          </p>
          <p>
            C. “<strong>Personal Information</strong>” means information about a determined or identifiable individual. This includes but is not limited to your name, address, telephone number, occupation, profession or personal occupation, images, video or photos that may allow proper identification, e-mail address, and any other sensitive information that may be related to race; ethnical condition; political position; religious, philosophical or moral convictions; union affiliation; biometric or genetic data; health condition or sexual preference, or any other considered as personal or sensitive data, regulated or protected under data privacy laws.
          </p>
          <p>
            D. “<strong>Site</strong>” includes without limitation the https://sprintlio.com/ website, software, hardware, tangible and intangible assets, property managed, licensed or used by Sprintlio at its principal pace of business for operating and making available the Purpose to Internet users, registered accounts, Site Content, and information posted and accessible therein.
          </p>
          <p>
            E. A “<strong>User</strong>” is someone who accesses, browses or in any way uses the Site or Site Content.
          </p>

          <h2>2. Purpose, Scope and Intent</h2>
          <p>2.1. Sprintlio has created and established this Site as part of the Purpose. The purpose of this Privacy Policy is to set out the principles governing our collection, use, processing and disclosure of the Personal Information we may obtain about you during your visit to or use of the Site or otherwise.</p>

          <h2>3. Information We Collect from You</h2>
          <p>3.1. We collect your Personal Information primarily from you. We may also collect it from other sources including agents and contractors or other third parties who represent that they have the right to disclose the information.</p>
          <p>
            3.2. When you register for an Account, we may collect certain information from you, including the following: <strong>your first and last name, your e-mail address, your profile picture, your password, your team name and your organization’s name</strong>. We do not collect your <strong>mailing address, phone number or location</strong>.
          </p>
          <p>3.3. From time to time, we may request other Personal Information in order to provide features, functions or services, but you will always have the choice to decide whether or not to provide the requested information. If you access the restricted access portions of the Site or request certain services, you may be required to provide additional Personal Information after giving your clear, express, affirmative consent at such time.</p>

          <h2>4. Your Consent</h2>
          <p>
            4.1. We may collect, process, use or disclose your Personal Information only with your consent or as permitted or required by law. <strong>You will always be asked for your clear, express, affirmative consent to the collection, processing, use and disclosure of your Personal Information when it is requested.</strong> If for whatever reason we ask to collect Personal Information relating to your fundamental rights and freedoms, including for example your ethnic origin, political opinions or religious beliefs, we will make it abundantly clear that we are asking for your explicit consent to the collection and processing of such information.
          </p>
          <p>4.2. We also obtain your clear, express, affirmative consent in your registration of an Account, or by facsimile, e-mail or telephone conversations with our employees, representatives or agents.</p>
          <p>4.3. We also obtain consent from individuals representing to us that they have the authority to provide such consent.</p>
          <p>4.4. The form of consent may vary depending upon the circumstances and the type of information, including the sensitivity of the information and your reasonable expectations, however we will always make sure that any consent you provide is clear, express and affirmative.</p>
          <p>
            4.5. You may opt in to receiving marketing or promotional communications from us at the time of registering an Account or subsequently by contacting us or by online subscribe / unsubscribe. You may <a href="https://sprintlio.us18.list-manage.com/unsubscribe?u=e9c4bf550e5db38588b2bea11&id=c4ff44eb81">unsubscribe here</a> or by contacting us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>. Please note that opting out will not stop us from sending you important business communications concerning your relationship with us (e.g. information about your subscriptions, service announcements or security information). You may also withdraw your consent for any other identified purposes at any time, subject to requirements under applicable laws. We may not be able to provide you with a particular service, including without limitation access to an Account or the Site, if you withdraw your consent to certain uses of Personal Information.
          </p>

          <h2>5. What We Do with Your Personal Information</h2>
          <p>5.1. Personal Information provided by you in connection with your business relationship with us, may be used by, collected by, processed by and/or disclosed by us (including our agents and member organizations) for the following purposes:</p>
          <ul>
            <li>To evaluate your Account registration application and your eligibility for an Account;</li>
            <li>To enable us to enter into contractual relationships with you, including without limitation the Terms of Use;</li>
            <li>For contract management and administration;</li>
            <li>To provide information and services that you purchase and/or request, and to respond to your comments and inquiries;</li>
            <li>In top-level aggregated form to attract users and other Account Holders, as well as to expand, reduce and otherwise make changes to the Site (such aggregated reports will not contain personally identifiable information);</li>
            <li>For marketing and promotion of services from Sprintlio to you;</li>
            <li>To meet legal and regulatory requirements or to disclose information when we believe in good faith we are compelled to do so by law, a subpoena, court order, regulatory body or similar legal procedure;</li>
            <li>If we believe in good faith that the disclosure is necessary to prevent physical harm or financial loss or to report suspected illegal activity;</li>
            <li>In connection with business relationships involving Sprintlio, only where such third party receiving this Personal Information first agrees to be bound by the terms of this Privacy Policy, and provided you have consented to such disclosure to the extent required by law;</li>
            <li>To analyze data individually or in aggregate to assist in our internal economic and demographic projections and to display that aggregated data; and</li>
            <li>As may be otherwise authorized by you.</li>
          </ul>
          <p>5.2. If your Personal Information is to be used, processed or disclosed for any new purposes, we will, to the extent required by law, identify these new purposes prior to such use, processing or disclosure and obtain your clear, express, affirmative consent.</p>
          <p>5.3. We will not sell any of your Personal Information that you provide us or provide to a third party for the purpose of solicitation by a third party.</p>
          <p>5.4. We may aggregate information (gather up data across all User Accounts) or anonymize such information that is not publicly available, including User generated Content, in a manner that creates non-identifiable information and disclose such information to third parties for other marketing, promotional, statistical and useful purposes. In these situations, Sprintlio does not disclose to these entities any Personal Information. You may at any time object to the use and/or processing of your Personal Information in connection with these purposes.</p>
          <p>
            5.5. In some cases Personal Information may be disclosed, processed and/or stored outside of the country in which you or we reside, and therefore may be available to government authorities under lawful orders and laws applicable there. For instance, to facilitate our business practices, your Personal Information may be collected, transferred to and stored by us in Canada and/or the United States. You may contact us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a> to find out whether your Personal Information is being processed and where.
          </p>
          <p>
            5.6. You are also entitled, at any time, to contact us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a> in the event that you wish to restrict or object to the processing of your Personal Information. We will cooperate with any and all requests that are in accordance with applicable laws. You may complain to a supervisory authority if you feel that we are not responding reasonably to your requests.
          </p>
          <p>
            5.7. You may, at any time, request that we confirm what Personal Information of yours is in our possession by contacting us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>. You may also at any time request that we tell you how long your Personal Information will be stored for, how we determine the duration of this storage period and who exactly is in receipt of your Personal Information. We are happy to provide as much information as reasonably possible about how your Personal Information is processed, and will cooperate with any and all requests that are in accordance with applicable laws. Generally, we may retain your Personal Information for a period of time consistent with the original purpose of collection. We determine this period based on the amount, nature and sensitivity of your Personal Information, whether we can achieve our purposes through other means, and applicable legal requirements. After the applicable period expires, we delete your Personal Information.
          </p>
          <p>
            5.8. Lastly, you may at any time request that we delete all of your Personal Information that is in our possession by contacting us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>, and we will cooperate with any and all requests that are in accordance with applicable laws.
          </p>

          <h2>6. Privacy and the Internet</h2>
          <p>6.1. On the Site we may also collect information that, alone, cannot be used to identify users. Examples of these types of information include IP addresses, cookies, Internet tags and navigational data.</p>
          <p>6.2. An Internet Protocol (IP) address is a number automatically assigned to users’ computers when they access the Internet. This number does not identify the user’s name, e-mail address or other Personal Information. This information may be used to perform certain operations including without limitation to measure website traffic, to help to understand how people are using the website and to improve the website and services provided.</p>
          <p>6.3. We use popular information gathering tools, including cookies, log files, web beacons and similar tools to automatically collected information (which may contain Personal Information) from your computer or mobile device as you use the Site. For instance:</p>
          <ul>
            <li>Cookies are small text files that our Site sends to your browser, which collect and provide information to us about your general Internet usage, such as recognizing repeat visitors and allowing us to understand how and when pages are visited. We use both session-based and persistent cookies. Session cookies exist only during one session and disappear from your computer when you close your browser software or turn off your computer. Persistent cookies remain on your computer or device after you close your browser or turn off your computer. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our Site. You may learn more about, or opt out of our use of cookies here.</li>
            <li>The information we gather using log files may include your IP address (or proxy server), device and application identification numbers, your location, your browser type, your Internet service provider and/or mobile carrier, the pages and files you viewed, your searches, your operating system and system configuration information, and date/time stamps associated with your usage. This information is used to analyze overall trends, to help us provide and improve our Site and to guarantee its security and continued proper functioning.</li>
            <li>We also use web beacons on our Site. For example, we may place web beacons in marketing emails that notify us when you click on a link in the email that directs you to our Site. Such tracking technologies are used to operate and improve our Site and email communications and track the clicking of links or opening of emails.</li>
            <li>
              We may use our own technology and third party technology to track and analyze usage and volume statistical information to provide enhanced interactions and more relevant communications. For example, we use Google Analytics, which uses cookies to help us analyze how our Site is used, including the number of visitors, the websites visitors have come from, and the pages they visit. This information is used by us to improve our Site. To opt out of data collection by Google Analytics, you may download and install the browser add-on available <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
            </li>
            <li>
              We also use cookies on your computer or mobile device to show you ads for our products that we think may interest you, to track their performance and to track the performance of our advertisements. Cookies in this case might collect and remember information like which browsers visit our Site. For example, we used Google AdWords to record the sales performance of our advertising campaigns, and to create an audience to retarget with advertisements. Such information may be provided to third parties but excludes Personal Information. To opt out of data collection by Google AdWords, you may download and install the browser add-on available <a href="https://tools.google.com/dlpage/gaoptout">here</a>.
            </li>
            <li>
              We may also contract with third party advertising networks, which may collect your IP address and other information from web beacons on our Site, from emails and on third party sites. These third parties may follow your online activities across different sites and devices, and may also use cookies. These third parties use the information to provide ads based on your interests. We do this to help manage and track our marketing efforts. If you wish to learn more/opt out of collection by these third parties, please contact us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>.
            </li>
          </ul>
          <p>6.4. The Site and electronic messages sent by or on behalf of Sprintlio may contain links to other sites. The provision of these links is for your convenience only, and does not constitute an endorsement, recommendation or approval of such other sites. This Privacy Policy is no longer applicable when you leave our Site by way of links or otherwise. Linked sites may or may not maintain their own independent privacy policies and procedures. You should consult these policies and procedures before providing any Personal Information to these linked sites.</p>

          <h2>7. Maintaining the Accuracy of Your Personal Information</h2>
          <p>7.1. You are solely responsible for the accuracy, completeness and up-to-date aspect of the Personal Information you provide to us. You are entitled to request that we rectify your Personal Information if you notice any errors or inconsistencies, following which it will be our obligation to promptly amend or update your Personal Information. We shall use reasonable efforts to keep our records of your Personal Information accurate, complete and up-to-date based upon the Personal Information we receive from you.</p>
          <p>7.2. You, or a person authorized by you, are responsible for informing us about changes to your Personal Information that are necessary to fulfill the identified purposes, including changes to your name or contact information.</p>

          <h2>8. Safeguarding Your Personal Information</h2>
          <p>
            8.1. We take commercially reasonable precautions to protect your Personal Information (regardless of the format in which it is held) against loss or theft, unauthorized access, unauthorized disclosure, copying, use, or modification, through safeguards appropriate to the sensitivity of the Personal Information. You may learn more about our security measures, including how we manage data security, <a href="/security">here</a>.
          </p>
          <p>8.2. Access to your Personal Information is only given to our employees who need to know the information and whose duties reasonably so require the information in order to fulfill the identified purposes.</p>
          <p>8.3. Sprintlio has adopted physical, procedural and technological procedures, including firewalls, in an effort to keep your Personal Information safe. Our Site uses SSL technology, which is an open protocol for secure data communications across computer networks. Data transmitted over the Internet, however, is not guaranteed to be secure and we do not guarantee, warrant or represent that the Personal Information you provide will be transmitted securely or protected completely against interception, loss, misuse or alteration. Sprintlio accepts no responsibility or liability for any damages that may result where Personal Information submitted by you has not been securely transmitted, or has been intercepted, lost, misused or altered.</p>
          <p>8.4. Sprintlio uses commercially reasonable technical, physical, operational and procedural safeguards to protect Personal Information and Your Content from loss, misuse, alteration, and unauthorized access or disclosure. Due to the nature of Sprintlio’s business, including its dependence on the public Internet, Sprintlio cannot ultimately ensure that private communications and Personal Information and Your Content will not be intercepted by third parties or otherwise obtained in ways not described in this Privacy Policy. Although Sprintlio attempts to protect the privacy of users, Sprintlio cannot absolutely guarantee that such information will remain private. While no security measure can guarantee against compromise, Sprintlio regularly reviews and updates its security measures in an effort to provide appropriate security for all information held by Sprintlio.</p>
          <p>8.5. You should be aware that Sprintlio has no control over the security of other websites on the Internet that you might visit, even where such other site is linked to our Site. If you share your computer or use a computer that is accessed by the general public, remember to sign off and close your browser window when you have finished your session. This will help to ensure that others cannot access your information.</p>
          <p>8.6. By submitting your credit card information through the Site, you expressly consent to the sharing of this information with our Payment Partner (as defined in the Terms of Use). Our Payment Partner will collect, store, and process your credit card and other payment information on our behalf using industry-standard security measures. We do not store any payment and/or credit information, except for the last 4 digits of your credit card number for ease of reference if you wish to make a purchase in the future and select a card to use, however our Payment Partner may store payment and/or credit information. We assume no responsibility or liability whatsoever with respect to your credit information in the event of a breach of confidentiality or security.</p>
          <p>8.7. In the event that a data breach involving your Personal Information occurs, and that the breach is likely to result in a risk to your rights and freedoms, we will notify you of the breach within 72 hours of its occurrence and without undue delay. We will explain the nature of the breach, provide contact information for further information, explain our understanding of the likely consequences of the breach and set out the measures we will take to address the breach and mitigate against its adverse effects. We will also maintain an up to date register of data breaches involving your Personal Information.</p>
          <p>8.8. We retain your Personal Information only as long as necessary or relevant (a) for the identified purposes, (b) as required by law, (c) as required by agreements with third parties related to the Purpose, (d) where we use your Personal Information to make a decision about your application to register an Account, long enough to allow you access to the information for a reasonable period of time after the decision has been made, and (e) where your Personal Information is the subject of a request from you, for as long as is reasonably necessary to allow you to exhaust any recourse you may have under the law. We shall use commercial reasonable efforts and protocol to dispose of or destroy your Personal Information to prevent unauthorized parties from gaining access to the information. We will not retain Personal Information if we become aware that such information is being unlawfully processed.</p>

          <h2>9. Special Note About Children</h2>
          <p>9.1. The Purpose and the Site is not directed towards children, nor does Sprintlio believe that the Purpose or the Site will be of interest to children. Sprintlio recognizes, however, the important safety issues regarding children who access the Internet. Sprintlio does not knowingly collect, use, or disclose Personal Information from children, and is committed to complying with all applicable laws and requirements. To remove the information related to a child who has registered an Account and permanently remove all Personal Information regarding this child from the Sprintlio database, you should contact our Privacy Officer as provided for herein.</p>

          <h2>10. Promotional Communications</h2>
          <p>10.1. When registering an Account, we may offer you the option of receiving notification of special offers by us and other promotional information. You must indicate your affirmative, express consent to receive this valuable service.</p>

          <h2>11. Fraud</h2>
          <p>11.1. If we suspect fraud by an Account Holder, we may investigate and report any evidence, including personally identifiable information, to law enforcement and to the courts in the course of seeking remedies in law or equity.</p>
          <p>11.2. Any suspected breaches to the security of the Site should be immediately reported to our Privacy Officer as provided for herein.</p>

          <h2>12. Individual Access</h2>
          <p>12.1. Subject to restrictions detailed herein and within reasonable terms, you may obtain access to files on your Personal Information by making a formal request to our Privacy Officer. Following your request we will promptly provide you with a copy of your Personal Information in a machine readable format. We can also provide a copy of your Personal Information to assist with transferring it to another data controller.</p>

          <h2>13. Account Cancellation</h2>
          <p>13.1. You may cancel, close and terminate your Account at any time in accordance with our Terms of Use.</p>

          <h2>14. Language and Governing Law</h2>
          <p>14.1. Where we provided you with a translation of the English language version of the Privacy Policy or any other documentation, you agree that the translation is provided solely for your convenience and that the English language versions of the Privacy Policy and any other documentation will govern the Privacy Policy and your relationship with Sprintlio and the Purpose.</p>
          <p>14.2. This Privacy Policy and all actions contemplated by the same shall be governed by and construed and enforced in accordance with the internal laws of the Province of Ontario, Canada, including the principles of conflict of laws thereof. All acts involving the registration, use or operation of any Account or of the Site shall be deemed as accessed, entered, perfected or finally accepted, at the place of the location of Sprintlio’s headquarters, irrespective of the location of the Site servers. All acts shall be considered as lastly accepted and perfected by Sprintlio and at Sprintlio’s headquarters.</p>

          <h2>15. Our Privacy Policies and Practices</h2>
          <p>15.1. You will be asked to provide clear, express, affirmative consent to the collection, processing and use of your information as Sprintlio has outlined in this Privacy Policy and to the Terms of Use. Sprintlio reserves the right to change, modify or update the terms of this Privacy Policy at any time, and such changes, modifications or updates will take effect following your clear, express, affirmative consent thereto. Sprintlio posts the revised Privacy Policy on the Site and indicates the date of the current statement below, so you know when it was last updated. You should regularly check the Privacy Policy for revisions. Changes will take effect upon the later of their posting, or your consent being obtained.</p>
          <p>15.2. You may challenge the accuracy and completeness of your Personal Information held by us and ask us to amend the information as appropriate. Under certain conditions as permitted or required by law, we may not provide you with access to your Personal Information kept in our file. Where we refuse your request, we will identify the reasons for refusing the request.  If a complaint is found to be justified, we will take appropriate measures including, if necessary, amending our policies and practices.</p>
          <p>
            15.3. We have appointed a Privacy Officer who shall be accountable for our compliance with applicable privacy laws and this Privacy Policy. If you have inquiries regarding your Personal Information held by us or if you wish to address a challenge regarding our compliance with this Privacy Policy, you may contact our Privacy Officer by email at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>.
          </p>
          <p>
            15.4. We also comply with the General Data Protection Regulation, which is designed to protect the Personal Information of European citizens and give them greater control over such information. To learn more, contact us at <a href="mailto:support@sprintlio.com">support@sprintlio.com</a>.
          </p>

          <p>
            <strong>All inquiries and concerns will be fully investigated and responded to by our Privacy Officer.</strong>
          </p>
          <p>
            <strong>This Privacy Policy is subject to applicable privacy laws and our right to change it at any time at our sole discretion.</strong>
          </p>
        </DocPage>
      </Inner>
    </Outer>
  </DocPageLayout>
);

export default PrivacyPage;
